<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-select
            type="search"
            codeGroupCd="EDU_STATUS_CD"
            itemText="codeName"
            itemValue="code"
            name="documentStatusCd"
            label="진행상태"
            v-model="searchParam.documentStatusCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-select
            :editable="editable"
            type="search"
            codeGroupCd="EDU_KIND_FST_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdLarge"
            label="교육종류1"
            v-model="searchParam.educationKindCdLarge"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-select
            :editable="editable"
            type="search"
            codeGroupCd="EDU_KIND_SEC_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdSmall"
            label="교육종류2"
            v-model="searchParam.educationKindCdSmall"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-select
            type="search"
            codeGroupCd="EDU_CLASS_CD"
            itemText="codeName"
            itemValue="code"
            name="educationTypeCd"
            label="교육구분"
            v-model="searchParam.educationTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-datepicker
            :range="true"
            name="period"
            label="교육일시"
            defaultStart="-6M"
            defaultEnd="6M"
            v-model="period"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <!-- <div class="card-header q-table-header">
        교육결과
      </div> -->
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(props.row)">
                <div :class="['text-grid-etc', 'grid-status-div', getColorStatus(props.row.documentStatusCd)]">
                  {{ props.row.documentStatusName }}
                </div>
                <q-card-section class="grid-card-etc">
                  <div class="txt-box-grid text-gray-box">
                    {{ props.row.educationKindCdLargeName }}
                  </div>
                  <div v-if="props.row.educationMethodCd == 'EMC999999'"  class="txt-box-grid text-vod-box">
                    <i class="material-icons" style="margin-bottom:2px">video_camera_back</i> {{ getEduMethod(props.row) }}
                  </div>
                  <div v-if="props.row.eduQuestionMstId" class="txt-box-grid text-question-box">
                    <i class="material-icons" style="margin-bottom:2px">question_answer</i> {{ getEduQuestion(props.row) }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.educationName }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div :class="['text-grid-etc', getColorEndFlag(props.row.checkCompleteFlag)]">
                    {{ getEndFlag(props.row.checkCompleteFlag) }} 
                  </div>
                  <div class="text-grid-etc">
                    {{ props.row.educationDate }} 
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section horizontal class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">{{$language('데이터가 존재하지 않습니다.')}}</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "education-result",
  data() {
    return {
      grid: {
        columns: [
          {
            name: "educationName",
            field: "educationName",
            label: "교육명",
            style: 'width:100px',
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "educationDate",
            field: "educationDate",
            label: "교육일시",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        documentStatusCd: null,
        startYmd: '',
        endYmd: '',
        educationKindCdSmall: null,
        educationKindCdLarge: null,
        educationTypeCd: null,
        userId: '',
      },
      useFlagItems: [
        { code: 'ESC000005', codeName: '계획완료' },
        { code: 'ESC000010', codeName: '교육완료' },
      ],
      period: [],
      editable: true,
      listUrl: "",
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  beforeRouteLeave(to, from, next) { // history.back 일경우 팝업이라면 팝업을 닫자
    if (this.popupOptions.visible) {
      this.closePopup();
      next(false);
    } else {
      next();
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.sop.edu.result.list.url;
      this.getUrl = selectConfig.sop.edu.result.get.mobile.url;
      // code setting
      this.searchParam.userId = this.$store.getters.user.userId;


      if (this.$route.query) {
        this.getUserDetail();
      }
      // list setting
      this.getList();
    },
    getList() {
      if (this.period && this.period.length > 0) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getUserDetail() {
      if (this.$route.query.eduEducationId) {
        this.$http.url = this.$format(this.getUrl, this.$route.query.eduEducationId, this.$store.getters.user.userId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (_result.data) {
            this.goSigPopup(_result.data)
          } else {
            window.getApp.$emit('ALERT', {
              title: '안내',
              message: '교육대상자가 아닙니다.',
              type: 'warning', // success / info / warning / error
            });
          }
        })
      }
    },
    goSigPopup(data) {
      if (data.documentStatusCd === 'ESC000010') {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '이미 완료된 교육입니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.linkClick({eduEducationId: this.$route.query.eduEducationId})
        // this.popupOptions.title = '교육명[' + data.educationName + ']'; // SOP 검색
        // this.popupOptions.param = {
        //   eduEducationId: this.$route.query.eduEducationId,
        //   attendeesId: this.$store.getters.user.userId,
        //   electronSignature: data.electronSignature,
        //   documentStatusCd: data.documentStatusCd,
        //   eduQuestionFlag: data.eduQuestionFlag,
        //   eduQuestionMstId: data.eduQuestionMstId,
        //   eduQuestionPassScore: data.eduQuestionPassScore,
        //   educationMethodCd: data.educationMethodCd,
        // };
        // this.popupOptions.target = () => import(`${'./signaturePop.vue'}`);
        // this.popupOptions.width = '100%';
        // this.popupOptions.visible = true;
        // this.popupOptions.closeCallback = this.closePopup;
      }
    },
    /* eslint-disable no-unused-vars */
    linkClick(row) {
      this.popupOptions.title = "교육결과 상세"; // 교육결과 상세
      this.popupOptions.param = {
        eduEducationId: row.eduEducationId ? row.eduEducationId : '',
      };
      this.popupOptions.target = () =>
        import(`${"./educationComplete.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'ESC000001': // 계획작성중
          cls = 'txt-box-grid text-orange-box';
          break;
        case 'ESC000005': // 계획완료
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'ESC000010': // 교육완료
          cls = 'txt-box-grid text-positive-box';
          break;
      }
      return cls;
    },
    getColorEndFlag(cd) {
      var cls = '';
      switch(cd) {
        case 'Y': // 이수
          cls = 'txt-box-grid text-gray-box';
          break;
        default: // 미이수
          cls = 'txt-box-grid text-red-box';
          break;
      }
      return cls;
    },
    getEduMethod(_row) {
      if (_row.educationMethodCd == 'EMC999999') {
        return this.$language('VOD');
      } else {
        return '';
      }
    },
    getEduQuestion(_row) {
      if (_row.eduQuestionMstId) {
        return this.$language('문제');
      } else {
        return '';
      }
    },
    getEndFlag(_cd) {
      if (_cd == 'Y') {
        return this.$language('이수');
      } else {
        return this.$language('미이수');
      }
    }
  },
};
</script>
